import React, { useState, useEffect } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import EventHead from "./EventHead/EventHead";
import EventAbout from "./EventAbout";
import EventPricing from "./EventPricing/EventPricing";
import EventShout from "./EventShout";
import EventNavBar from "../GeneralComponents/EventNavBar";
import eventContext from "../../../contexts/EventContext";
import Loading from "../../../components/Loading";
import EventVenue from "./EventVenue";
import Navbar from "../../../components/NavigationBar/Navbar";
import EventSpeakers from "./EventSpeakers";
import EventSponsors from "./EventSponsors";
import EventWorkshop from "./EventWorkshop";
import Footer from "../../../components/Footer";
import EventAgenda from "./AgendTrendy";
import { pricingSampleData } from "../../../data/sampleEvent";
import { errorToaster } from "../../../helpers/utils";
import { getTicketData } from "../../../crud/dedicatedEvent-crud";
import moment from "moment";
import _ from "lodash";

const EventPageTrendy = ({
	isReady,
	eventUrl,
	query,
	eventData,
	samplePage = false,
	ticketsData,
	groupDiscounts
}) => {
	const [currentUrl, setCurrentUrl] = useState("");
	const [ticketData, setTicketData] = useState(ticketsData);
	const router = useRouter();
	let newQuery = { ...query };
	delete newQuery.eventUrl;
	const faviconIconUrl =
		eventData.fav_icon_urls && eventData.fav_icon_urls["favicon.ico"];

	useEffect(() => {
		//to pass over the
		if (!samplePage) {
			if (isReady && eventUrl) {
				let currentUrl = router.asPath;
				if (currentUrl.includes("#")) currentUrl = currentUrl.split("#")[0];
				setCurrentUrl(currentUrl);
			} else if (isReady && !eventUrl) router.push("/error");
		}
	}, [eventUrl]);

	useEffect(() => {
		if (samplePage) {
			setTicketData(pricingSampleData.uncategorized);
		} else {
			//getTicketDataFunction();
		}
	}, [eventUrl]);

	const hideHighlight =
		eventData?.expected_speakers === 0 && eventData?.expected_attendees === 0;

	const getTicketDataFunction = async () => {
		try {
			const res = await getTicketData(eventUrl);
			// const res = { data: { ...ticketData } };
			let uncategorized = res.data.uncategorized;
			let allTickets = [...uncategorized];
			res.data.categorized = res.data.categorized.sort(
				(a, b) => a.category_order - b.category_order,
			);
			res.data.categorized.forEach((category) => {
				category.tickets = category.tickets.map((ticket) => {
					ticket.category = {
						category_name: category.category_name,
						category_description: category.category_description,
						category_id: category.category_id,
						category_order: category.category_order,
						is_expanded: category.is_expanded,
					};
					return ticket;
				});
				allTickets = [...allTickets, ...category.tickets];
			});
			let ticketDetails = allTickets;
			let orderedTicket = ticketDetails.sort((a, b) => {
				// Sort the forms by from orderzz
				return a.ticket_order - b.ticket_order;
			});
			let newTickets = [];
			orderedTicket.map((ticket) => {
				if (!ticket.hidden_ticket) newTickets.push(ticket);
				else return;
			}); // temporary
			setTicketData(newTickets);
		} catch (err) {
			console.log(err);
			errorToaster(
				"Something went wrong while trying to get ticket, please try again later",
			);
		}
	};

	const isTicketSoldOut = () => {
		var todaysTime = moment().valueOf();
		let event_end_timestamp = `${eventData.end_date} ${eventData.end_time}`;
		let eventEndTime = moment.utc(event_end_timestamp).local().valueOf();
		if (!_.isEmpty(ticketData)) {
			let soldoutTickets = ticketData.filter(
				(ticket) =>
					(ticket.sold_out === true || ticket.remaining_count <= 0) &&
					!ticket.waitlist_enabled,
			);
			if (soldoutTickets.length === ticketData.length) {
				return "Sold Out";
			}
		}
		if (eventData.is_free) {
			return "Register";
		} else if (todaysTime > eventEndTime) {
			return "Expired";
		} else return "Buy Now";
	};

	const showNavbar = (eventData) => {
		if (
			eventData &&
			(eventData.description ||
				(eventData.is_virtual === true && eventData.event_website) ||
				(eventData.venue !== "" &&
					eventData.is_virtual === false &&
					eventData.event_website === "") ||
				ticketData.length > 0 ||
				eventData.has_coupons ||
				eventData?.has_speakers === true ||
				eventData?.has_sponsors === true ||
				eventData?.has_workshops === true ||
				eventData?.has_agenda === true)
		) {
			return true;
		} else return false;
	};

	return (
		<>
			<eventContext.Provider value={{ eventData }}>
				{showNavbar(eventData) && (
					<EventNavBar
						eventData={eventData}
						eventUrl={eventUrl}
						currentUrl={currentUrl}
						ticketData={ticketData}
						hasCoupons={eventData.has_coupons}
						hasAccessCodes={eventData.has_access_codes}
						showNavbar={showNavbar}
					/>
				)}
				{Object.keys(eventData).length !== 0 ? (
					<>
						{faviconIconUrl ? (
							<Head>
								<link rel="icon" href={faviconIconUrl} />
							</Head>
						) : (
							<Head>
								<link rel="icon" href="./favicon/globe.ico" />
							</Head>
						)}
						<div className="event-header-container event-header-container-image px-0">
							<Navbar
								color="#fff"
								logoWhite
								disableLogo={eventUrl == "linkedin" ? true : false}
								mobileNavbar={false}
								hideLinks={true}
								eventPage={true}
							/>
							<EventHead
								eventUrl={eventUrl}
								hideHighlight={hideHighlight}
								currentUrl={currentUrl}
								buttonText={isTicketSoldOut()}
								hasCoupons={eventData.has_coupons}
								ticketData={ticketData}
							/>
						</div>
						{eventData.description && (
							<EventAbout eventUrl={eventUrl} hideHighlight={hideHighlight} />
						)}
						{!eventData.is_virtual && eventData.venue && <EventVenue />}
						<EventPricing
							eventUrl={eventUrl}
							newQuery={newQuery}
							samplePage={samplePage}
							hasCoupons={eventData.has_coupons}
							hasAccessCodes={eventData.has_access_codes}
							ticketData={ticketData}
							getTicketDataFunction={getTicketDataFunction}
							setTicketData={setTicketData}
							groupDiscounts={groupDiscounts}
						/>
						{(eventData?.speaker_section_description ||
							eventData?.has_speakers === true) && (
							<EventSpeakers
								eventUrl={eventUrl}
								eventData={eventData}
								samplePage={samplePage}
							/>
						)}
						{(eventData?.sponsor_section_description ||
							eventData?.has_sponsors === true) && (
							<EventSponsors
								eventUrl={eventUrl}
								eventData={eventData}
								samplePage={samplePage}
							/>
						)}
						{eventData?.has_workshops === true && (
							<EventWorkshop
								eventUrl={eventUrl}
								eventData={eventData}
								samplePage={samplePage}
							/>
						)}
						{eventData?.has_agenda === true && (
							<EventAgenda eventData={eventData} />
						)}
						<EventShout />
						{/*

            <Footer />
             */}
					</>
				) : (
					<div className=" vh-100 d-flex justify-content-center align-items-center bg-light align-self-center event-header-container event-header-container-image px-0">
						<Loading color="#fb5850" />
					</div>
				)}
			</eventContext.Provider>
			<style jsx global>{`
        .eventpage-fixed-padding {
          padding: 4rem 3rem 4rem 3rem;
        }

        .event-header-container {
          background: #260e20;
        }

        @media only screen and (min-width: 992px) {
          .event-header-container-image {
            background: #260e20 url("/img/event-bg.svg") no-repeat;
            background-size: 100% auto;
            position: relative;
          }
        }

        @media only screen and (max-width: 992px) {
          .event-header-container-image {
            background: #260e20 url("/img/event-bg-overlay.svg") no-repeat;
            background-size: contain;
            position: relative;
          }
        }
        @media only screen and (max-width: 575px) {
          .eventpage-fixed-padding {
            padding: 4rem 2rem 4rem 2rem;
          }
          }
      `}</style>
		</>
	);
};

export default EventPageTrendy;
