import React, { useState, useEffect, useContext } from 'react'
import TicketInfo from './TicketInfo'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getTicketData } from "../../../../crud/dedicatedEvent-crud";
import { errorToaster } from '../../../../helpers/utils';
import CouponInput from './../../GeneralComponents/CouponInput';
import { pricingSampleData } from '../../../../data/sampleEvent';
import { categorizeTickets, checkTeamBasedRegStatus } from '../../../../helpers/common';
import eventContext from '../../../../contexts/EventContext';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import _ from 'lodash';

const TeamBasedComponent = dynamic(
	() => import("../../GeneralComponents/TeamBased/TeamBasedComponent"),
);

const settings = {
  dots: false,
  infinite: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  arrows: false
};


const EventPricing = ({ eventUrl, newQuery, samplePage, getTicketDataFunction, hasCoupons, hasAccessCodes, ticketData, setTicketData, isLoading, groupDiscounts }) => {

  const { eventData } = useContext(eventContext);
  const router = useRouter();

  const [couponCode, setCouponCode] = useState("");
  const [uncategorized, setUncategorized] = useState([]);
  const [categorized, setCategorized] = useState([]);
  const [teamRegStatus, setTeamRegStatus] = useState(0); // 0 -> No team based tickets, 1 -> Only team based tickets, 2 -> team based and individual tickets 
	const [isAccessCode, setIsAccessCode] = useState(false);

  const isReferral = !_.isEmpty(newQuery) && newQuery.referred_by !== "" && newQuery.referral_mode !== "" && newQuery.campaign_id !== ""
  useEffect(() => {
    const { uncategorized, categorized } = categorizeTickets(ticketData);
    const teamRegistrationStatus = checkTeamBasedRegStatus(ticketData);
    setTeamRegStatus(teamRegistrationStatus)
    setUncategorized(uncategorized);
    setCategorized(categorized);
  }, [ticketData])

  const getCouponDetails = (couponDetails = false, coupon, accessCodeTicket = false) => {
    let newTicketDetails = [];
    accessCodeTicket.length > 0 ? newTicketDetails = [ ...accessCodeTicket ] : newTicketDetails = [...ticketData]
    const couponTickets = couponDetails.applicable_tickets;
    newTicketDetails.forEach(ticket => {
      const ticketPrice = ticket.ticket_price
      ticket.final_price = ticketPrice;
      ticket.discount_remaining = null;
      ticket.coupon_applicable = null;
      ticket.discounted_price = null;

      let finalPrice;
      //If coupon code and not access code
      if (couponDetails && !accessCodeTicket) {
        ticket.discount = null;
        if (couponTickets.includes(ticket.ticket_id)) {
          ticket.coupon_applicable = true;
          ticket.discount = couponDetails.discount;
          if (couponDetails.discount_type === 1) {
            finalPrice = ticketPrice - ticket.discount;
            ticket.discounted_price = ticket.discount;
            ticket.final_price = finalPrice.toFixed(2) % 1 != 0 ? finalPrice.toFixed(2) : finalPrice;
          } else if (couponDetails.discount_type === 2) {
            finalPrice = ticketPrice - ((ticket.discount / 100) * ticketPrice);
            ticket.discounted_price = ((ticket.discount / 100) * ticketPrice);
            ticket.final_price = finalPrice.toFixed(2) % 1 != 0 ? finalPrice.toFixed(2) : finalPrice;
          }
          if (finalPrice <= 0) {
            ticket.final_price = 0;
          }
        }
      } else { // If access code
        ticket.coupon_applicable = true;
        if (teamRegStatus > 0) { //If access code is coming from a team based event
          ticket.teamBasedEvent = true;
        }
        if (ticket.discount_type === 1) {
          finalPrice = ticketPrice - ticket.discount;
          ticket.discounted_price = ticket.discount;
          ticket.final_price = finalPrice.toFixed(2) % 1 != 0 ? finalPrice.toFixed(2) : finalPrice;
        } else if (ticket.discount_type === 2) {
          finalPrice = ticketPrice - ((ticket.discount / 100) * ticketPrice);
          ticket.discounted_price = ((ticket.discount / 100) * ticketPrice);
          ticket.final_price = finalPrice.toFixed(2) % 1 != 0 ? finalPrice.toFixed(2) : finalPrice;
        }
        if (finalPrice <= 0) {
          ticket.final_price = 0;
        }
      }
    })
    setCouponCode(coupon);
    setTicketData(newTicketDetails);
  };

  const removeCoupon = (isAccessCode = false) => {
    if (!isAccessCode) {
      let newTicketDetails = [...ticketData]
      newTicketDetails.forEach(ticket => {
        ticket.final_price = ticket.ticket_price;
        ticket.discount = null;
        ticket.discount_remaining = null;
        ticket.discounted_price = null;
      })
      setTicketData(newTicketDetails);
    } else if (isAccessCode) {
      //   setTicketData("");
      getTicketDataFunction();
    }
    setCouponCode("")
  }


  return (
    <>
      {(ticketData?.length > 0 || hasCoupons || hasAccessCodes) && <div className="pricing-container d-flex flex-column align-items-center eventpage-fixed-padding" id="tickets">
        <div className="w-100 py-sm-0 py-2 mt-sm-0 mt-5 mb-sm-0 mb-3 d-flex flex-row justify-content-around align-items-center">
          <h1 className="ticket-txt">{ticketData?.length === 1 ? 'Ticket' : 'Tickets'}</h1>
          {/* <h1 className="pricing-txt ms-sm-3 ms-2">Pricing</h1> */}
        </div>
        {(hasCoupons || hasAccessCodes && !isLoading) && (
          <>
            <p className='discount-text text-center'>
            {hasCoupons && !hasAccessCodes && 'Apply discount code to avail discounts.'}
            {!hasCoupons && hasAccessCodes && 'Apply access code to view hidden tickets.'}
            {hasCoupons && hasAccessCodes && 'Apply discount code / access code to avail discounts & view hidden tickets'}
            </p>
            <CouponInput 
              getCouponDetails={getCouponDetails} 
              removeCoupon={removeCoupon} 
              newQuery={newQuery}
              setIsAccessCode={setIsAccessCode}
              isAccessCode={isAccessCode}
            />
          </>
        )}
        {teamRegStatus === 0 ? (
          ( ticketData.length === 0 ? (
            <div className="ticket-container container mt-5 p-3 rounded d-flex justify-content-center">No tickets available </div>
          ) : (
            <div className="ticket-container container mt-5 p-3 rounded justify-content-center">
            {uncategorized.map((i, index) => <TicketInfo key={index} groupDiscounts={groupDiscounts} eventUrl={eventUrl} data={i} newQuery={newQuery} couponCode={couponCode} />)}
            {
              categorized.length > 0 &&
              <div className='mx-3 my-3'>
                {
                  categorized.map((category, categoryIndex) => (
                    <div key={category.category_id} className="accordion" id={`accordion-${category.category_id}`}>
                      {
                        category.tickets.length > 0 &&
                        <div className="category-box mt-4 accordion-item">
                          <h2 className="accordion-header" id={`heading-${category.category_id}`} style={{ marginBottom: "0px" }}>
                            <button 
                              className={`accordion-button ${category.is_expanded ? "" : "collapsed"}`}  
                              type="button" 
                              data-bs-toggle="collapse" 
                              data-bs-target={`#collapse-${category.category_id}`} 
                              aria-expanded={category.is_expanded} 
                              aria-controls={`collapse-${category.category_id}`}
                            >
                              {category.category_name}
                              <span className="accordion-arrow ms-2"></span>
                            </button>
                          </h2>
                          <div 
                            id={`collapse-${category.category_id}`} 
                            className={`accordion-collapse collapse ${category.is_expanded ? "show" : ""}`} 
                            aria-labelledby={`heading-${category.category_id}`} 
                            data-bs-parent={`#accordion-${category.category_id}`}
                          >
                            <div className='tickets accordion-body p-0 p-sm-3'>
                              {category.category_description &&
                                <div dangerouslySetInnerHTML={{ __html: category.category_description }} />
                              }
                              {
                                category.tickets.map(ticket => (
                                  <TicketInfo key={ticket.ticket_id} groupDiscounts={groupDiscounts} eventUrl={eventUrl} data={ticket} couponCode={couponCode} newQuery={newQuery} />
                                ))
                              }
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  ))
                }
              </div>
            }
          </div>) )
        ) : (
          <div className='ticket-container container mt-5 p-3 rounded justify-content-center'>
              <TeamBasedComponent color="#fb5850" teamRegStatus={teamRegStatus} eventData={eventData} onJoinTeam={(team) => {
                sessionStorage.setItem("team_name", team.team_name)
                sessionStorage.setItem("team_id", team.team_id)
                sessionStorage.setItem("team_attendee_count", team.team_attendee_count);
                if(isAccessCode){
                  sessionStorage.setItem("isEventPageTeamComp", true);
                }
                router.push(
                  `/checkout/${eventData.event_url}?teamRegType=2&team_name=${team.team_name}&team_id=${team.team_id}${couponCode ? `&selectedCode=${couponCode}` : ""}${isReferral ? `&referred_by=${newQuery.referred_by}&campaign_id=${newQuery.campaign_id}&referral_mode=${newQuery.referral_mode}` : ""}${isAccessCode ? `&isAccessCode=1` : ""}`
                )
              }}
              onCreateTeam={(val) => {
                sessionStorage.setItem("team_name", val.team_name)
                sessionStorage.setItem("team_lead_name", val.team_lead_name)
                sessionStorage.setItem("team_lead_email", val.team_lead_email)
                if(isAccessCode){
                  sessionStorage.setItem("isEventPageTeamComp", true);
                }
                router.push(
                  `/checkout/${eventData.event_url}?teamRegType=1&team_name=${val.team_name}&team_lead_name=${val.team_lead_name}&team_lead_email=${val.team_lead_email}${couponCode ? `&selectedCode=${couponCode}` : ""}${isReferral ? `&referred_by=${newQuery.referred_by}&campaign_id=${newQuery.campaign_id}&referral_mode=${newQuery.referral_mode}` : ""}${isAccessCode ? `&isAccessCode=1` : ""}`,
                )
              }}
              onIndividual={()=>{
                  if(couponCode && isAccessCode){
                  // if multiple hidden tickets with team_based and without team_based ticket
                  // and if select individual
                    sessionStorage.setItem("isEventPageTeamComp", true);
                    router.push(
                      `/checkout/${eventData.event_url}?teamRegType=0&selectedCode=${couponCode}${isAccessCode ? `&isAccessCode=1` : ""}`
                    )
                  } else {
                    router.push(
                      `/checkout/${eventData.event_url}?teamRegType=0`
                  )
                  }
                }}
              />
            </div>
        )}
      </div>}
      <style jsx>{`
                .pricing-container{
                    height: auto;
                    overflow: hidden;
                }
                .ticket-txt{
                    font-family: Prompt;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 64px;
                    line-height: 130%;
                    text-align: center;
                    color: #572148;
                }
                .pricing-txt{
                    font-family: Caveat Brush;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 64px;
                    line-height: 130%;
                    color: #FB5850;
                }
                .ticket-container {
                  max-width: 720px;
                  background: #F6F4EF;
                }
                .category-box {
                  border-radius: 5px !important;
                  border: 2px solid #EFE9ED;
                  background: #FFF;
                  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.15);
                }
                .accordion-button {
                  font-size: 18px;
                  border-radius: 5px !important;
                  font-weight: 600;
                }
    
                .accordion-button:not(.collapsed) {
                  background-color: #FFF;
                  color: #212529;
                  box-shadow: inset 0 -1px 0 rgba(0,0,0,.125);
                }
                @media only screen and (max-width: 575px) {
                  .ticket-txt{
                    font-size: 42px;
                  }
                }
                `}</style>
    </>
  )
}

export default EventPricing