import React, { useContext } from 'react'
import Link from "next/link";
import eventContext from '../../../../contexts/EventContext'
// import moment from 'moment';
import moment from 'moment-timezone';
import { truncate } from 'lodash';
import ReadMore from '../../../../components/ReadMoreText'
import { convertTimezone } from '../../../../helpers/common';


const EventTitle = ({ eventUrl, currentUrl, buttonText, hasCoupons, ticketData }) => {

    const { eventData } = useContext(eventContext)

    const getDate = (date, format) => {
        return moment(convertTimezone(date, eventData.time_zone)).format(format);
    }

    let event_start_timestamp = `${eventData.start_date} ${eventData.start_time}`;
	let event_end_timestamp = `${eventData.end_date} ${eventData.end_time}`;
	const localTimezone = moment.tz.guess();

    const date = {
		start_month: moment.utc(event_start_timestamp).local().format("MMM Do"),
		end_month: moment.utc(event_end_timestamp).local().format("MMM Do"),
		start_day: moment.utc(event_start_timestamp).local().format("ddd"),
		end_day: moment.utc(event_end_timestamp).local().format("ddd"),
		start_year: moment.utc(event_start_timestamp).local().format("YYYY"),
		end_year: moment.utc(event_end_timestamp).local().format("YYYY"),
		start_time: moment.utc(event_start_timestamp).local().format("h:mm A"),
		end_time: moment.utc(event_end_timestamp).local().format("h:mm A"),
		timezone: moment.tz(event_end_timestamp, localTimezone).format("z"),
	};


    const getTimeZone = () => {
        if (date.timezone.includes("+")) {
            return `GMT${date.timezone}`
        } else {
            return date.timezone;
        }
    }
    // console.log(moment.tz(`${eventData.end_date} ${eventData.end_time}`,"z", eventData.time_zone).zoneAbbr());

    const isSameDate = (dateValue) => {
        if ((dateValue.start_month == dateValue.end_month) && (dateValue.start_year == dateValue.end_year)) {
            return `${dateValue.start_month}, ${dateValue.start_year} ${dateValue.start_time} to ${dateValue.end_time} ${getTimeZone()}`
        } else {
            return `${dateValue.start_month}, ${dateValue.start_year} ${dateValue.start_time} - ${dateValue.end_month}, ${dateValue.end_year} ${dateValue.end_time} ${getTimeZone()}`
        }
    }

    const checkIfDisabled = (buttonText) => {
        if(buttonText === "Sold Out" || buttonText === "Expired") {
            return "disabled-btn-title"
        } else return ""
    }

    return (
        <>
            <div className="col-xl-6 col-md-12 pt-4 d-flex flex-column align-items-start position-relative ms-3">
                {/* <span className="org-title ">{eventData.organiser_name}&apos;s</span> */}
                <div className="spark-image">
                    <img src="/img/spark.svg" alt="This is an orange picture showing a spark" />
                </div>
                <div className='col-md-12' >
                    <h2 className="event-title col-md-12 col-12 col-xl-12 mt-3 " >
                        <ReadMore
                            maxChar={"auto"}
                            content={eventData.name}
                            forcedHeight="min-content"
                            buttonTextClassName='display-6'
                            buttonTextStyle={"font-size: 25px"}
                        />
                    </h2>
                </div>
                <span className="event-date mt-3 ">{isSameDate(date)}</span>
                {(eventData.city && !eventData.is_virtual) && <Link href={`${currentUrl}#venue`} scroll={false}>
                    <span style={{ cursor: "pointer" }} className='mt-3'> <i style={{ fontSize: '26px', color: '#F63C3D' }} className="fa fa-map-marker-alt" aria-hidden="true"></i> <span className="event-city   ">{eventData.city}</span></span>
                </Link>}
                {(eventData.is_virtual && eventData.event_live_link) ? (
                    <a href={eventData.event_live_link} target='_blank' className='text-decoration-none text-white mt-3'>
                        <span style={{ cursor: "pointer" }} className=''>
                            <i style={{ fontSize: '26px', color: '#F63C3D' }} className="fa-solid fa-globe" aria-hidden="true"></i> <span className="event-city   ">Online</span>
                        </span></a>) : (eventData.is_virtual && !eventData.event_live_link) ? (
                            <span className='mt-3'>
                                <i style={{ fontSize: '26px', color: '#F63C3D' }} className="fa-solid fa-globe" aria-hidden="true"></i> <span className="event-city   ">Online</span>
                            </span>
                        ) : ""}
                {/* <span className="event-time mt-3 ms-1">{date.start_time} | {date.end_time} | {date.timezone}</span> */}

                <div className="row mb-5 w-100 d-flex justify-content-start pe-5">
                    {eventData.event_website ?
                        <a href={eventData.event_website} target="_blank" className="site-link-text text-white text-decoration-none text-center">
                            <div className="col-12 site-link-btn align-items-center justify-content-around border border-3 rounded mt-4 py-3 me-0 me-sm-5">
                                Official Website
                                <i className="fa fa-external-link fa-lg text-white website-icon" aria-hidden="true"></i>
                            </div>
                        </a>
                        : null}
                   {(ticketData?.length > 0 || hasCoupons) && <Link href={`${currentUrl}#tickets`} scroll={false}>
                        <a className={`site-link-text text-white text-center text-decoration-none buy-btn d-flex align-items-center justify-content-around rounded mt-4 py-3 px-3 ${checkIfDisabled(buttonText)}`} id="register-btn-evtpage-header"
                           onClick={(e) => {
                           e.preventDefault();
                           const ticketsSection = document.getElementById('tickets');
                           if (ticketsSection) {
                           ticketsSection.scrollIntoView({ behavior: 'smooth' });
                           }
                        }}>
                           {buttonText}
                        </a>
                    </Link>}
                </div>
            </div>
            <style>{`
            .org-title{
                font-family: Caveat Brush;
                font-style: normal;
                font-weight: normal;
                font-size: 35px;
                line-height: 130%;
                color: #FB5850;
            }
            .disabled-btn-title {
                background: #a09f9f !important;
                cursor: not-allowed;
                pointer-events: none;
            }
            .spark-image{
                transform: rotate(-93.88deg);
                position: absolute;
                left: -25px;
                top: 25px;
            }
            .event-title{
                font-family: Prompt;
                font-style: normal;
                font-weight: bold;
                font-size: 48px;
                line-height: 130%;
                color: #FFFFFF;
                word-break: break-word !important;
            }
            .event-date{
                font-family: Caveat Brush;
                font-style: normal;
                font-weight: normal;
                font-size: 40px;
                line-height: 130%;
                color: #F63C3D;
            }
            .event-city{
                font-family: Caveat Brush;
                font-style: normal;
                font-weight: normal;
                font-size: 30px;
                line-height: 130%;
                color: #FFFFFF;
            }
            .website-icon {
                font-size: 1.4rem;
            }
            .event-time{
                font-family: Prompt;
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 130%;
                color: #FFFFFF;
            }
            .site-link-btn{
                display: flex;
                width: 250px;
                cursor: pointer;
                padding-left: 10px;
                padding-right: 10px;
                box-shadow: 0px 4px 5px rgb(0 0 0 / 15%);
            }
            .site-link-btn:active{
                box-shadow: none;
                transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            }
            .site-link-btn:hover{
                background: #F63C3D;
                border: 3px solid #F63C3D !important;
                transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            }
            .site-link-text{
                font-family: Prompt;
                font-style: normal;
                font-weight: 500;
                font-size: 20px;
                line-height: 27px;
                letter-spacing: 0.04em;
                display: inline-block;
                padding: 0px;
                width: auto;
            }
            .buy-btn:hover {
                color: black !important;
                transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            }
            .buy-btn{
                width: 180px;
                background-color: #F63C3D;
                cursor: pointer;
                box-shadow: 0px 4px 5px rgb(0 0 0 / 15%);
            }
            .buy-btn:active{
                box-shadow: none;
                transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
            }
            .rm-typography{
                margin: 0 !important;
                padding: 0 !important;
              }
              .rm-text-container{
                margin: 0 !important;
                padding: 0 !important;
              }
              .rm-action-button-container *{
                border: none !important;
                margin: 0 !important;
                padding: 0 !important;
                background:transparent;
                text-decoration:underline;
                line-height:0%;
                margin-bottom:10px !important;
                color: #fb5850;
              }
              .rm-action-button-container *:focus {
                box-shadow: none !important;
              }
            @media only screen and (max-width: 576px) {
                .org-title{
                    font-size: 30px;
                }
                .event-title{
                    font-size: 40px;
                }
                .event-date{
                    font-size: 35px;
                }
                // .site-link-btn{
                //     display:none;
                // }
                .site-link-text {
                    width: 100%;
                }
            }
                `}
            </style>
        </>
    )
}

export default EventTitle
